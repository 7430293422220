<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Amortización de Cueta - Editar</strong>

            <router-link class="btn btn-light float-right btn-sm"  :to="{ path: '/amortizacion-cuota/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off" @submit.prevent="Validate">
              <b-row>

                <b-col lg="6">
                  <b-form-group>
                    <label>Cliente:</label>
                    <v-select disabled placeholder="Seleccione un cliente" class="w-100" :filterable="false" label="text" v-model="client"></v-select>
                    <small v-if="errors.id_client" class="form-text text-danger" >Seleccione un cliente</small>
                  </b-form-group>
                </b-col>
                
                <b-col md="2">
                  <b-form-group label="Codigo:">
                    <b-form-input disabled class="text-center" type="text" v-model="installment_amortization.code"></b-form-input>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>


                <b-col md="2">
                  <b-form-group label="Medio de pago:">
                    <b-form-select v-model="installment_amortization.payment_method" :options="payment_method"></b-form-select>
                    <small v-if="errors.payment_method"  class="form-text text-danger" >Medio de Pago</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Fecha:">
                    <b-form-input type="date" disabled class="text-center" v-model="installment_amortization.date"></b-form-input>
                    <small v-if="errors.date"  class="form-text text-danger" >Ingrese un fecha</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Crédito:">
                    <b-form-input disabled class="text-center" v-model="installment_amortization.credit_code"></b-form-input>
                    <small v-if="errors.id_credit"  class="form-text text-danger" >Seleccione un crédito</small>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Nro Cuota:">
                    <b-form-input disabled type="text" class="text-center" v-model="installment_amortization.quota_number"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Saldo Capital:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="installment_amortization.capital_balance"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Interes:">
                    <b-form-input disabled type="number" step="any" class="text-right" v-model="installment_amortization.interest"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Cuota:">
                    <b-form-input disabled type="text" class="text-right" v-model="installment_amortization.quota"></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group label="Amortización:">
                    <b-form-input type="number" step="any" class="text-right" v-model="installment_amortization.total"></b-form-input>
                    <small v-if="errors.total"  class="form-text text-danger" >Ingrese un monto</small>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group label="Observación:">
                    <b-form-textarea v-model="installment_amortization.observation"></b-form-textarea>
                  </b-form-group>
                </b-col>

               

               

       
                <b-col md="4"></b-col>
                <b-col md="2">
                  <b-button class="form-control" @click="OpenVoucher" type="button" variant="warning">Imprimir Recibo</b-button>
                </b-col>
                
                <b-col md="2">
                  <b-button type="submit" class="form-control" variant="primary" ><i class="fas fa-save"></i> Guardar (F4)</b-button>
                </b-col>
                
              </b-row>
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading"/>
    <Keypress key-event="keyup" :key-code="115" @success="Validate" />
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
import DataCollection from "@/assets/js/DataCollection";// components
// components
import LoadingComponent from './../pages/Loading'
export default {
  name: "CreditPaymentAdd",
  props: ["id_installment_amortization"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'InstallmentAmortization',
      role: 3,
      installment_amortization: {
          id_installment_amortization:'',
          id_client:'',
          id_user:'',
          id_branch_office:'',
          id_credit:'',
          id_credit_detail:'',
          code:'',
          credit_code:'',
          payment_method:'008',
          date:moment(new Date()).local().format("YYYY-MM-DD"),
          observation:'',
          total:'0.00',
          state:1,
          quota_number : '',
          capital_balance : '',
          interest : '',
          quota : '',
      },
      file:null,
      modules:[],
      // payment_method:[
      //   {value:'008', text:'EFECTIVO'},
      //   {value:'007', text:'CHEQUES'},
      //   {value:'001', text:'DEPÓSITO EN CUENTA'},
      //   {value:'003', text:'TRANSFERENCIA DE FONDOS'},
      // ],
      client: null,
      clients: [],
      credits: [
      {value:'',text:'- Seleccione -'},
      ],
      errors: {
        id_client: false,
        payment_method: false,
        date: false,
        total: false,
        id_credit:false,
        id_credit_detail: false,
      },
      validate: false,
    };
  },
  mounted() {
    this.ViewIntallmentAmortization();
  },
  methods: {
    ViewIntallmentAmortization,
    EditInstallmentAmortization,
    Validate,
    OpenVoucher,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
    payment_method: function () {
      return DataCollection.MethodPayment();
    }
  },
};

function ViewIntallmentAmortization() {
  let me = this;
  let id_installment_amortization = je.decrypt(this.id_installment_amortization);
  let url = me.url_base + "installment-amortization/view/"+id_installment_amortization;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
          me.installment_amortization.id_installment_amortization = response.data.result.id_installment_amortization;
          me.installment_amortization.id_client = response.data.result.id_client;
          me.installment_amortization.id_user = response.data.result.id_user;
          me.installment_amortization.id_branch_office = response.data.result.id_branch_office;
          me.installment_amortization.id_credit = response.data.result.id_credit;
          me.installment_amortization.id_credit_detail = response.data.result.id_credit_detail;
          me.installment_amortization.code = response.data.result.code;
          me.installment_amortization.payment_method = response.data.result.payment_method;
          me.installment_amortization.date = response.data.result.date;
          me.installment_amortization.observation = response.data.result.observation;
          me.installment_amortization.total = response.data.result.total;
          me.installment_amortization.state = response.data.result.state;
          me.installment_amortization.quota_number  = response.data.result.quota_number;
          me.installment_amortization.capital_balance  = response.data.result.capital_balance;
          me.installment_amortization.interest  = response.data.result.interest;
          me.installment_amortization.quota  = response.data.result.quota;
          me.installment_amortization.credit_code  = response.data.result.credit_code;
          me.client = { value: response.data.result.id_client, text: response.data.result.client_name};
      }
    });
}

function EditInstallmentAmortization() {
  let me = this;
  let url = me.url_base + "installment-amortization/edit";
  me.isLoading = true;
  this.installment_amortization.id_client = this.client.value;
  this.installment_amortization.id_user = this.user.id_user;
  this.installment_amortization.id_branch_office = this.user.id_branch_office;
  let data = this.installment_amortization;
  axios({
    method: "PUT",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function Validate() {
  this.errors.id_client = this.client == null ? true : false;
  this.errors.payment_method = this.installment_amortization.payment_method.length == 0 ? true : false;
  this.errors.total = this.installment_amortization.total.length == 0 || parseFloat(this.installment_amortization.total) <= 0  ? true : false;

  if (this.errors.id_client) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.payment_method) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }
  if (this.errors.total) { this.validate = true; Swal.fire({ icon: 'warning', text: 'Verifique que campos necesarios esten llenados', timer: 2000,}); return false;}else{ this.validate_add = false; }

  let me = this;
  Swal.fire({
    title: "Esta seguro de modificar la amortización de la cuota?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.EditInstallmentAmortization();
    }
  });
}

function OpenVoucher() {
  let me = this;
  let url1 = me.url_base + "installment-amortization-bill-of-sale/"+ this.installment_amortization.id_installment_amortization;
  let url2 = me.url_base + "installment-amortization-sales-of-receip/"+ this.installment_amortization.id_installment_amortization;
  

  let url = me.url_base + "installment-amortization/get-information-voucher/"+this.installment_amortization.id_installment_amortization;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        if (parseFloat(response.data.result.interest) > 0) {
          var ventana1 = window.open(url1, '_blank');
        }

        if (parseFloat(response.data.result.capital_balance) > 0) {
          setTimeout(function(){
              var ventana2 = window.open(url2, '_blank');
          }, 250);
        }
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}


</script>
